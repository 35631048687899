import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import media from '../media'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className,children }) => (
    <StaticQuery query={query}
     render={data => {
       // Set ImageData.
       const imageData = data.desktop.childImageSharp.fluid
       return (
      
          <BackgroundImage Tag="section"
                           className={className}
                           fluid={imageData}
                           backgroundColor={`#000000`}
          >
            {children}
          </BackgroundImage>
          
       )
     }
     }
    />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  ${media.mobileS`
  height:auto;
  background-size: auto;
  background-position: bottom left;
  `}
  ${media.tablet`
  height:auto;
  background-size: auto;
  background-position: bottom left;
  `}
  ${media.laptop`
  height:80vw;
  background-size: auto;
  background-position: bottom center;
  `}
  ${media.laptopL`
  height:60vw;
  `}
  ${media.desktop`
  height:50vw;
  `}

  width: 100%;
  height:60vw;
  /* background-position: bottom center; */
  background-repeat: repeat-y;
  background-size: cover;
`
export const query = graphql`
query {
  desktop: file(relativePath: { eq:  "bg/playgroundBG.png"}) {
    childImageSharp {
      fluid(quality: 90, maxWidth:2560) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`
export default StyledBackgroundSection