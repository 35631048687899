import React from 'react'
import PlaygroundBG from './playgroundBG'
import styled from 'styled-components'
import media from '../media'
import GradientButton from '../gradientButton/gradientButton'


const StyledTopText = styled.h2`
color:white;
font-family:sans-serif;
 ${media.mobileS`
 font-size:1.5rem;
 text-align:center;
 padding-top:20%;
 `}

 ${media.tablet`
  text-align:left;
  padding-left:10%;
 `}
 ${media.laptop`
 padding-top:15%;
 `} 
 ${media.laptopL`
 font-size:2rem;
 `}
 ${media.desktop`
 font-size:3rem;
 `}
`
const StyledTextDecal = styled.div`
 ${media.mobileS`
 margin:auto;
 width:30%;
 `}
 ${media.tablet`
  margin-left:10%
  width:15%;
 `}
background-color:orange;
width:15%;
height:4px;
margin-bottom:5%;
`
const StyledMainSection = styled.div`
display:flex;
color:white;
font-family:sans-serif;
${media.mobileS`
  flex-direction:column;
  justify-content:space-between;
`}
${media.laptop`
  flex-direction:row;
  align-items:center;
`}
`

const StyledTextContent = styled.div`
display:flex;
flex-direction:column;
${media.laptop`
  width:60%;
  margin-left:4%;
`}
`
const StyledTitle = styled.h2`
color:orange;
${media.mobileS`
padding-top:5%;
width:95%;
text-align:center;
margin:auto;
`}
${media.tablet`
  text-align:left;
  padding-left:10%;
  width:100%;
  margin:3% 0;
`}
${media.laptop`
  font-size:2rem;
  margin-left:0;
  width:50%;
`}
${media.desktop`
font-size:3.5rem;
width:50%;
`}
`

const StyledSubtitle = styled.p`
${media.mobileS`
width:90%;
text-align:center;
margin:5% auto;
`}
${media.mobileL`
width:85%;
`}
${media.laptop`
  /* width:50%; */
  text-align:left;
  margin-left:10%;
`}
${media.desktop`
font-size:2.5rem;
line-height:2.5rem;
`}
`
const StyledExternalLink = styled.a`
text-decoration:none;
color:white;
${media.desktop`
  font-size:2.5rem;
`}
`
const StyledGradientButtonContainer = styled.div`
${media.mobileS`
margin:5% auto;
padding-bottom:15%;
`}
${media.laptopL`
margin:5% auto;
margin-right:10%;
padding-bottom:5%;

`}

${media.desktop`
margin-right:20%;
`}

`
const StyledPGContainer = styled.div``
const playground = () => {
    return (
       <PlaygroundBG>
        <StyledPGContainer>
            <StyledTopText id="playground">
                Welcome to
            </StyledTopText>
            <StyledTextDecal/>
          <StyledMainSection>
            <StyledTextContent>
              <StyledTitle>Temporal Interplanetary Playground</StyledTitle>
              <StyledSubtitle>
              An inspiring place to explore what Temporal can do for you. It is an easy-to-use interface for a Dropbox-like pinning service that allows you to interact with all the features of IPFS supported by our API. Get started uploading, encrypting and searching files on the IPFS network today with 3GB for free.
              </StyledSubtitle>
            </StyledTextContent>
            <StyledGradientButtonContainer>
              <GradientButton><StyledExternalLink href="https://play.temporal.cloud" >Enter Now</StyledExternalLink></GradientButton>
            </StyledGradientButtonContainer>
          </StyledMainSection>

         </StyledPGContainer>
       
        </PlaygroundBG>
    )
}

export default playground