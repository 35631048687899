import React from 'react'
import Gbi from './HeroBG'
import {Link} from 'gatsby'
import GradientButton from '../gradientButton/gradientButton'
import styled from 'styled-components'
import Header from '../header/header'
import media from "../media"




const StyledHeroContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`
const StyledHeroHeader = styled.h1`
${media.mobileS`
width:80%;
font-size:2rem;
margin-top:15%;
`}
${media.tablet`
width:80%;
text-align:center;
`}
${media.laptop`
margin-top:0;
`}
${media.desktop`
padding-top:12%;
`}

padding-top:7rem;
color:white;
text-transform:uppercase;
`
const StyledSpan = styled.span`
${media.mobileS`
font-size:0.9rem;
`}
${media.tablet`
  font-size:1rem;
`}
${media.laptop`
  font-size:1.3rem;
`}        
color:orange;
`
const StyledDeco = styled.span`
${media.mobileS`
 display:block;
`}
${media.tablet`
 display:block;
`}
color:orange;
`
const StyledUl = styled.ul`
${media.mobileS`
font-size:0.9rem;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin:auto;
  padding:5% 10%;
`}
${media.tablet`
  font-size:1rem;
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 25%;
  padding-top: 1rem;
`}
  `
const StyledLi = styled.li`
${media.mobileS`
  font-size:0.9rem;
`}   
${media.tablet`
  font-size:1rem;
`} 
${media.laptop`
  font-size:1.3rem;
`}    

      color:white;
      font-family: sans-serif;
      list-style:none;
`

const StyledTextDecal = styled.div`
${media.mobileS`
width:40%;
`}
background: linear-gradient(45deg, #ff8e53 30%, #fe6b8b 90%);
width:15%;
height:4px;
margin-top:2%;
margin-bottom:4%;
`
const StyledButtonContainer = styled.div`
display:flex;
justify-content:center;
padding-top:2rem;
`

const hero = () => {
    return (
        <Gbi>

      <Header/>    
        <StyledHeroContainer>
          <StyledHeroHeader >
          {/* style={{display:"flex", flexDirection:"column",justifyContent:"flex-start"}} */}
            Enterprise 
            <span style={{color:"orange"}}> IPFS Platform</span>
          </StyledHeroHeader>
            </StyledHeroContainer>
            <div style={{display:"flex",justifyContent:"center"}}>
            <StyledTextDecal/>
          </div>
     
          <StyledUl>
            <StyledLi>
              <StyledSpan>S</StyledSpan>ecure
            </StyledLi>
            <StyledDeco >&#9830;</StyledDeco>
            <StyledLi>
              <StyledSpan>E</StyledSpan>asy-to-Use
            </StyledLi>
            <StyledDeco >&#9830;</StyledDeco>
            <StyledLi>
              <StyledSpan>C</StyledSpan>ost-Effective
            </StyledLi>
          </StyledUl>

            <StyledButtonContainer>
          <GradientButton ><Link to="/temporal" style={{color:"white",textDecoration:"none"}}>View Our Products</Link></GradientButton>
          </StyledButtonContainer>       
     
        </Gbi>
    )
}

export default hero
