import React from "react"
import Layout from "../components/layout"
import {Helmet} from "react-helmet"
// import SEO from "../components/seo"
import HeroSection from '../components/home/hero'
import AboutSection from '../components/home/aboutSection'
import FaqFeaturesSection from '../components/home/faqFeaturesSection'
import Testimony from '../components/home/testimony'
import Partners from '../components/home/partners'
import Playground from '../components/home/playground'
import MobileHeader from '../components/header/headerMobile'
import CookieConsent from "../components/cookieConsent"
import {graphql} from 'gatsby'

const IndexPage = ({data}) => {
  console.log(data);
 return( 
 
<Layout>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Temporal.Cloud</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta property="og:title" content="Temporal.Cloud"/>
          <meta property="og:description" content="Enterprise IPFS Platform"/>
          {/* <meta property="og:image" content={data.file.childImageSharp.original.src}/> */}
          <meta property="og:image" content="https://temporal.cloud/HomePreview.png"/>
          <meta property="og:url" content="https://temporal.cloud/"/>
          <meta property="og:type" content="website"/>
          <meta property="og:locale" content="en_US"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:site" content="@Temporalcloud"/>
          <meta name="twitter:title" content="Temporal.Cloud"/>
          <meta name="twitter:description" content="Enterprise IPFS Platform"/>
          <meta name="twitter:image" content="https://temporal.cloud/HomePreview.png"/>
        </Helmet>
      </div>
<div>
          {/* <SEO title="Home" /> */}
            <MobileHeader/>
            <HeroSection/>
            <AboutSection/>
           <FaqFeaturesSection/>
           {/* <Testimony/> */}
           <Partners/>
           <Playground/>
           <CookieConsent/>
  </div>
  </Layout> 

  )}

export const query = graphql`
query {
  file(relativePath:{eq:"temporal-preview.png"}){
    childImageSharp{
 		original{
      src
    }   
    }
  }

}


`


export default IndexPage
