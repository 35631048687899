import React from "react"
import styled from "styled-components"
import Slider from "react-animated-slider"
import horizontalCss from "react-animated-slider/build/horizontal.css"
import media from '../media'

const StyledCarouselWrapper = styled.div`
${media.mobileS`
padding:0;

`}
${media.mobileM`
padding:0% 2%;
`}
${media.tablet`
padding:10% 0;
padding-top:0;
`}
${media.laptop`
padding:10% 10%;
padding-top:0;
`}
 
 ${media.desktop`
padding:0;
`}
 
  max-width: 1280px;
  margin: auto;
`
const StyledCarousel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
`

const QuoteText = styled.h2`
${media.mobileS`
font-size:1rem;
width:80%;
padding-bottom:10%;
`}
${media.tablet`
font-size:1.2rem;
width:90%;
/* padding-bottom:10%; */
`}
${media.laptop`

width:100%;
/* padding-bottom:10%; */
`}
  text-align: center;
`
const QuoteAuthor = styled.h3`
color:orange;
${media.mobileS`
font-size:1rem;
text-align:center;
`}
${media.tablet`
font-size:1.2rem;
`}
  font-style: italic;
  /* margin-left: 2rem; */
  text-align: right;
  text-transform: capitalize;
`
const StyledTitleContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
`
const StyledTitle = styled.h1`
${media.mobileS`
font-size:1.5rem;
margin-top:5%;
`}
font-size:2rem;
font-family:sans-serif;
padding-top:3%;
`
const StyledTextDecal = styled.div`
background-color:orange;
width:10%;
height:4px;
margin-bottom:4%;
`
const StyledTestimonialContainer = styled.div`
background:linear-gradient(45deg, #B721FF, #21D4FD );
color:white;
`


const testimony = () => {
  return (
    <StyledTestimonialContainer>

    <StyledTitleContainer>
        <StyledTitle>
            Testimonials
        </StyledTitle>  
        <StyledTextDecal/>  
    </StyledTitleContainer>
    <StyledCarouselWrapper>
      <Slider classNames={horizontalCss} >
        <StyledCarousel>
          <QuoteText>
            "RTrade technologies not only provides us with the perfect solution of decentralized storage, but also give us a fresh new outlook on how the new blockchain storage industry will perform in the next 10 years."
          </QuoteText>

          <QuoteAuthor>- Charles Cao, Founder, Nebula AI</QuoteAuthor>
        </StyledCarousel>

        <StyledCarousel>
          <QuoteText>"RTrade’s approach with Temporal has the potential to fundamentally change the way enterprises manage data."</QuoteText>
          <QuoteAuthor>- Jay Breakstone, Founder, LIQID</QuoteAuthor>
        </StyledCarousel>

        <StyledCarousel>
          <QuoteText>"We have partnered with RTrade to create the planet’s first use case that guarantees trust, fair trade, anti-smuggling, anti-trafficking, and anti-crime chain of custody from true source to customer, using IPFS, Oracles, and Smart Contracts."</QuoteText>
          <QuoteAuthor>- Michael H Peters, Founder, TrustedBlockchain</QuoteAuthor>
        </StyledCarousel>
      </Slider>
    </StyledCarouselWrapper>
    </StyledTestimonialContainer>  
  )
}

export default testimony
