import React from 'react'
import FeaturesBG from './featuresBG'
import Features from './features'
import MobileFeatures from './featuresMobile'
import MobileFaqSection from './faqSectionMobile'
import FaqSection from './faqSection'
import {Link} from 'gatsby'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import media from '../media'

const StyledFaqButtonContainer = styled.div`
${media.mobileS`
padding-bottom:4rem;
padding-top:4rem;
`}

${media.laptop`
padding-top:0rem;
`}
display:flex;
justify-content:center;

`
const StyledApiButtonContainer = styled.div`
display:flex;
justify-content:center;
padding-bottom:4rem;
padding-top:4rem;
`



const faqFeaturesSection = () => {
    return (
       
        <FeaturesBG>

            <FaqSection/>
            <MobileFaqSection/>
            <StyledFaqButtonContainer>
        <Button variant="outlined" size="large" color="primary" ><Link style={{color:"orange"}} to="/faq">View Our FAQ</Link></Button>
        </StyledFaqButtonContainer>
            <Features/>
            <MobileFeatures style={{zIndex:"-1"}}/>

            <StyledApiButtonContainer>
                 <Button variant="outlined" size="large" color="primary" ><Link style={{color:"orange"}} to="/dev">View API Documentation</Link></Button>
            </StyledApiButtonContainer>

            
        </FeaturesBG>
       
    )
}

export default faqFeaturesSection
