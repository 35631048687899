import React from 'react'
import styled from 'styled-components'
import media from '../media'

const StyledFeaturesContainer = styled.div`
display:flex;
flex-direction:row;
justify-content:space-around;
`
const StyledFeature = styled.div`
background-color:grey;
color:white;
width:28%;
border-radius:8px;
&:hover{
background-color:cornflowerblue;
}
`
const StyledFeatureContent = styled.div`
padding:10% 10%;
`
const StyledFeatureTitle = styled.h2`
padding-top:10%;
text-align:center;
font-size:1.5rem;
`

const StyledFeatureText = styled.p`
text-align:center;
font-size:1rem;
font-family:Arial, Helvetica, sans-serif;
`
const StyledCenterAlignContainer = styled.div`
display:flex;
justify-content:center;
`
const StyledTextDecal = styled.div`
background-color:orange;
width:15%;
height:4px;

`
const StyledSectionTitle = styled.h1`
font-family:sans-serif;
font-size:2rem;
color:white;
`
const StyledOuterFeaturesContainer = styled.div`
${media.mobileS`
display:none;
`}
${media.laptop`
display:block;
`}
`

const features = () => {
    return (

        <StyledOuterFeaturesContainer>
        <StyledCenterAlignContainer>       
        <StyledSectionTitle>Key Temporal Features</StyledSectionTitle>
    </StyledCenterAlignContainer>
    <StyledCenterAlignContainer>
         <StyledTextDecal style={{ marginBottom:"5%"}}/>
    </StyledCenterAlignContainer>

        <StyledFeaturesContainer>
        <StyledFeature>                   
                <StyledFeatureTitle>
                Turnkey Automation
                </StyledFeatureTitle>
                <StyledCenterAlignContainer>
                    <StyledTextDecal/>
                    </StyledCenterAlignContainer>
                <StyledFeatureContent>
                    <StyledFeatureText>
                    Temporal does almost everything for you, which means you don't have to learn in detail how to use IPFS. Moderately experienced network and data engineers adopt it quickly.
                    </StyledFeatureText>
                    
                </StyledFeatureContent>
        </StyledFeature>
        <StyledFeature>                   
                <StyledFeatureTitle>
                Data Cost
                </StyledFeatureTitle>
                <StyledCenterAlignContainer>
                    <StyledTextDecal/>
                    </StyledCenterAlignContainer>
                <StyledFeatureContent>
                    <StyledFeatureText>
                    Temporal's peer-to-peer approach can save an enterprise millions of dollars in bandwidth alone, and manage big data in an immutable and much safer manner than HTTP. It also works very cost-effectively on small enterprises.
                    </StyledFeatureText>   
                </StyledFeatureContent>
        </StyledFeature>
        <StyledFeature>                   
                <StyledFeatureTitle>
                IPFS Storage
                </StyledFeatureTitle>
                <StyledCenterAlignContainer>
                    <StyledTextDecal/>
                    </StyledCenterAlignContainer>
                <StyledFeatureContent>                   
                    <StyledFeatureText>
                    Deduplication and content addressing characteristics of IPFS make it faster and more affordable to manage data; plus, it can be fully encrypted.
                    </StyledFeatureText>
                </StyledFeatureContent>
        </StyledFeature>
    </StyledFeaturesContainer>



</StyledOuterFeaturesContainer>

    )
}

export default features
