import React from "react"
import styled from "styled-components"
import Slider from "react-animated-slider"
import horizontalCss from "react-animated-slider/build/horizontal.css"
import media from '../media'


const StyledFeatureContent = styled.div`
padding:5% 10%;
`
const StyledFeatureTitle = styled.h2`
color:white;
padding-top:10%;
text-align:center;
font-size:1.5rem;

`

const StyledFeatureText = styled.p`
color:white;
text-align:center;
font-size:0.8rem;
font-family:Arial, Helvetica, sans-serif;
${media.mobileS`
    width:70%;
    margin:auto;
`}
${media.mobileM`
    width:80%;
    margin:auto;
`}

`
const StyledCenterAlignContainer = styled.div`
display:flex;
justify-content:center;
width:100%;
`
const StyledCarouselWrapper = styled.div`
${media.mobileS`
    padding:0;
`}
  padding: 3rem;
  padding-top:0;
  max-width: 1280px;
  margin: auto;
`
const StyledCarousel = styled.div`
${media.mobileS`
/* height:120vh; */
`}
background-color:dimgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 70vh; */
`

const StyledTitleContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
`
const StyledTitle = styled.h1`
color:white;
font-size:1.5rem;
font-family:sans-serif;
padding-top:3%;
`
const StyledTextDecal = styled.div`
background-color:orange;
width:10%;
height:4px;
margin-bottom:4%;
`

const StyledFeaturesContainer = styled.div`
${media.mobileS`
display:block;
z-index:-1;
`}
${media.laptop`
display:none;
`}
`

const featuresMobile = () => {
  return (
    <StyledFeaturesContainer>

    <StyledTitleContainer>
        <StyledTitle>
            Key Temporal Features
        </StyledTitle>  
        <StyledTextDecal/>  
    </StyledTitleContainer>

    <StyledCarouselWrapper>
      <Slider classNames={horizontalCss} >
        <StyledCarousel>
                        
                    <StyledFeatureTitle>
                    Turnkey Automation
                    </StyledFeatureTitle>
                    <StyledCenterAlignContainer>
                        <StyledTextDecal/>
                     </StyledCenterAlignContainer>
                    <StyledFeatureContent>
                        <StyledFeatureText>
                        Temporal does almost everything for you, which means you don't have to learn in detail how to use IPFS. Moderately experienced network and data engineers adopt it quickly.
                        </StyledFeatureText>
                    </StyledFeatureContent>
          
        </StyledCarousel>

        <StyledCarousel>
                     
                <StyledFeatureTitle>
                Data Cost
                </StyledFeatureTitle>
                <StyledCenterAlignContainer>
                    <StyledTextDecal/>
                </StyledCenterAlignContainer>
                <StyledFeatureContent>
                <StyledFeatureText>
                    Temporal's peer-to-peer approach can save an enterprise millions of dollars in bandwidth alone, and manage big data in an immutable and much safer manner than HTTP. It also works very cost-effectively on small enterprises.
                    </StyledFeatureText>
                    
                </StyledFeatureContent>
        
        </StyledCarousel>
        <StyledCarousel>
                 
                <StyledFeatureTitle>
                IPFS Storage
                </StyledFeatureTitle>
                <StyledCenterAlignContainer>
                    <StyledTextDecal/>
                    </StyledCenterAlignContainer>
                <StyledFeatureContent>
                <StyledFeatureText>
                    Deduplication and content addressing characteristics of IPFS make it faster and more affordable to manage data; plus, it can be fully encrypted.
                    </StyledFeatureText>
                </StyledFeatureContent>

        </StyledCarousel>
      </Slider>
    </StyledCarouselWrapper>
    </StyledFeaturesContainer>  
  )
}

export default featuresMobile
