import React from 'react'
import styled from 'styled-components'
import media from '../media'
import Img from "gatsby-image"
import {StaticQuery,graphql} from 'gatsby'


const StyledFaqColumn = styled.div`
background-color:dimgray;
width:60%;
padding: 2% 5%;
margin:5% auto;
display:flex;
flex-direction:column;
justify-content:center;
border-radius:8px;
&:hover{
background-color:cornflowerblue;
}
`
const StyledFaqText = styled.div`
display:flex;
color:white;
font-weight:bold;
${media.laptop`
font-size:1rem;
text-align:left;
padding-bottom:2%;
`}
${media.desktop`
margin:auto;
`}
font-family:Arial, Helvetica, sans-serif;
`

const StyledFaqOuterContainer = styled.div`
${media.mobileS`
display:none;
`}
${media.laptop`
display:block;
`}
`
// const StyledDeco = styled.span`

// color:orange;
// `
const StyledTitleContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;

${media.mobileS`
padding-top:20%;
`}

${media.tablet`
padding-top:10%;
`}


`
const StyledTitle = styled.h1`
color:white;
font-size:2rem;
font-family:sans-serif;
padding-top:5%;
`
const StyledTextDecal = styled.div`
background-color:orange;
width:10%;
height:4px;
margin-bottom:4%;
`

const StyledFaqSubtitle = styled.p`
    text-align: center;
    font-family:sans-serif;
    color:white;
  ${media.laptop`
  font-size: 1rem;
  padding:0 10%;

  `}
  ${media.laptopL`
  font-size:1.2rem;
  padding:0 20%;
  `}
  ${media.desktop`
  font-size:1.5rem;
  padding:0 20%;
  `}

`
const StyledSaturnContainer = styled.div`

${media.tablet`
width:25px;
`}
`
const faqSection = () => (
<StaticQuery
query={query}
render={data => 
    <StyledFaqOuterContainer>
        <StyledTitleContainer>
        <StyledTitle>
            What's Temporal?
        </StyledTitle>  
        <StyledTextDecal/>  
    </StyledTitleContainer>

    <StyledFaqSubtitle>Scalable IPFS Infrastructure to build production-ready applications on top of IPFS quickly with modern tools and languages such as Golang and JavaScript with free starter package featuring turnkey APIs, toolkits &amp; storage. Fully Automated - Install it and go. </StyledFaqSubtitle>
           
       
        <StyledFaqColumn>
            <StyledFaqText>    
                <StyledSaturnContainer>
                <Img fluid={data.saturnImage.childImageSharp.fluid}/>            
                </StyledSaturnContainer>
            &nbsp;Open-Source &amp; No Vendor Lock</StyledFaqText>
            <StyledFaqText>  
            <StyledSaturnContainer>
            <Img fluid={data.saturnImage.childImageSharp.fluid}/>
                 
            </StyledSaturnContainer>&nbsp;&nbsp;Plug Into Any Existing Application Seamlessly</StyledFaqText>
            <StyledFaqText>  <StyledSaturnContainer>
            <Img fluid={data.saturnImage.childImageSharp.fluid}/>
                  
            </StyledSaturnContainer>&nbsp;&nbsp;Verifiable Data Integrity Guaranteed</StyledFaqText>
            <StyledFaqText> <StyledSaturnContainer>
            <Img fluid={data.saturnImage.childImageSharp.fluid}/>
            </StyledSaturnContainer>&nbsp;Enterprise Pinning Service</StyledFaqText>
        </StyledFaqColumn>
    </StyledFaqOuterContainer>
      }

      />
    )
  
export const query = graphql`
query {
  saturnImage: file(relativePath: { eq: "saturn.png" }) {
    childImageSharp {
      fluid(maxWidth:35) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default faqSection
