import React from 'react'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'

const StyledButton = styled(Button)`
    border-radius: 100rem;
	padding: 1rem;
	font-family: $font-Text;
	font-size: 1rem;
	padding: .5rem 3rem;
	color: white;
	box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    /* background:linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%); */
	background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #78e4ff, #ff48fa);
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px #000000 inset; 
  &:hover {
	box-shadow: none;
    color: white;
  }
  `


const gradientButton = (props) => {
    return (
        <StyledButton>{props.children}</StyledButton>
    )
}

export default gradientButton



// background-image: linear-gradient(to right, #bd21a3 0%, #ee8c0c 100%);