import React from 'react'
import Img from 'gatsby-image'
import {StaticQuery, graphql } from 'gatsby'
// import {Link} from 'gatsby'
import styled from 'styled-components'
import media from '../media'
import ens from "../../images/partners/ens.svg"

const StyledImagesContainer = styled.div`
${media.mobileS`
flex-direction:row;
`}
${media.tablet`
flex-direction:row;
width:80%;
margin:auto;
`}
display:flex;
flex-wrap:wrap;
justify-content:space-around;
align-items:center;
padding-bottom:5%;
margin-top:1%;
`

const StyledLogo = styled.a`
${media.mobileS`
width:30%;
padding:0%;
margin:0%
z-index:0;

`}
${media.tablet`
width:22%;
padding:0;
`}
${media.laptop`
width:18%;
padding:0;
height:80%;
margin: 0% 3%;
`}

`
const StyledeeaLogo = styled.a`
${media.mobileS`
width:30%;
padding:5%;
z-index:0;

`}
${media.tablet`
width:15%;
padding:0;
height:80%;
padding-bottom:3%;
padding-top:2%;
`}
${media.laptop`
width:18%;
padding-top:2rem;
height:80%;
padding-left:2%;
margin-right:5%;
margin-left:3%;
padding-left:2%;
padding-bottom:5%;
`}

`

const StyledensLogo = styled.a`
${media.mobileS`
width:30%;
padding:5%;
z-index:0;

`}
${media.tablet`
width:15%;
padding:0;
height:80%;
padding-bottom:3%;
padding-top:2%;
margin-bottom:0;
margin-right:2%;
`}
${media.laptop`
width:18%;
padding:0;
height:80%;
margin: 0% 3%;
margin-right:5%;
margin-left:0%;
margin-top:2%;
`}

`

const StyledTrusted = styled.a`
${media.mobileS`
width:30%;
padding:5%;
margin:5% 0;
z-index:0;
`}
${media.tablet`
width:22%;
padding:0;
margin:0;
`}
${media.laptop`
width:22%;
padding:0;
height:80%;
`}
`


const StyledTitleContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
`
const StyledTitle = styled.h1`
${media.mobileS`
font-size:1.5rem;
`}
${media.tablet`
font-size:2rem;
`}
font-family:sans-serif;
padding-top:3%;
`
const StyledTextDecal = styled.div`
background-color:orange;
width:10%;
height:4px;
margin-bottom:4%;
`

const Partners = () => (

    <StaticQuery
      query={ImagesQ}
      render={data =>
        <div>
        <StyledTitleContainer>
            <StyledTitle>
                Our Partners &amp; Clients
            </StyledTitle>
           
                <StyledTextDecal/>
          
        </StyledTitleContainer>
       <StyledImagesContainer >

            <StyledLogo href="http://www.zhggp.cn">           
            <Img fluid={data.image1.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo href="https://chain.link/">
            <Img fluid={data.image2.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo href="https://trustedblockchain.com/">
            <Img fluid={data.image3.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo href="https://nebula-ai.com/#Home">
            <Img fluid={data.image4.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo href="http://ens.domains">
            <img src={ens} style={{marginBottom:"0"}}/>
            </StyledLogo>
            <StyledLogo href="https://exedao.com/" >
            <Img fluid={data.image6.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo href="https://textile.io/" >
            <Img fluid={data.image7.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo href="https://artbot.tv/#/" >
            <Img fluid={data.image8.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo href="https://dappkit.io/">
            <Img fluid={data.image9.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo href="https://rivet.cloud">
            <Img fluid={data.image10.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo href="https://entethalliance.org/" >
            <Img fluid={data.image5.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo >
            <Img fluid={data.image11.childImageSharp.fluid} />
            </StyledLogo>
          </StyledImagesContainer>          
        </div>
        }
    />
  

)

export const ImagesQ = graphql`
  fragment partnersLogo on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    image1: file(relativePath: { eq: "partners/zhgLogo.png" }) {
      ...partnersLogo
    }

    image2: file(relativePath: { eq: "partners/chainlink.png" }) {
      ...partnersLogo
    }
    image3: file(relativePath: { eq: "partners/TrustedBC.png" }) {
      ...partnersLogo
    }
    image4: file(relativePath: { eq: "partners/nebulas.png" }) {
      ...partnersLogo
    }
    image5: file(relativePath: { eq: "partners/eea.png" }) {
      ...partnersLogo
    }
    image6: file(relativePath: { eq: "partners/exedao.png" }) {
      ...partnersLogo
    }
    image7: file(relativePath: { eq: "partners/textile.png" }) {
      ...partnersLogo
    }
    image8: file(relativePath: { eq: "partners/Fleek.png" }) {
      ...partnersLogo
    }
    image9: file(relativePath: { eq: "partners/dappkit.png" }) {
      ...partnersLogo
    }
    image10: file(relativePath: { eq: "partners/rivet.png" }) {
      ...partnersLogo
    }
    image11: file(relativePath: { eq: "partners/artbot.png" }) {
      ...partnersLogo
    }
    # image11: file(relativePath: { eq: "partners/ens.svg" }) {
    #   ...partnersLogo
    # }

  }
`

export default Partners