import React from "react"
import styled from "styled-components"
import Slider from "react-animated-slider"
import horizontalCss from "react-animated-slider/build/horizontal.css"
import media from '../media'
import Img from 'gatsby-image'
import {StaticQuery, graphql } from 'gatsby'


const StyledCarouselWrapper = styled.div`
${media.mobileS`
    padding:0;
`}
  padding: 3rem;
  padding-top:0;
  max-width: 1280px;
  margin: auto;
`
const StyledCarousel = styled.div`
${media.mobileS`
 /* width:100%; */
 margin-bottom: 5%;
`}
${media.tablet`
width:30%;
`}
background-color:dimgray;
/* width:30%; */
/* padding: 2% 5%; */
/* margin:0 7%; */
display:flex;
flex-direction:column;
justify-content:center;
  /* height: 70vh; */
`

const StyledTitleContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;

${media.mobileS`
padding-top:20%;
`}

${media.tablet`
padding-top:10%;
`}


`
const StyledTitle = styled.h1`
color:white;
font-size:1.5rem;
font-family:sans-serif;
padding-top:5%;
`
const StyledTextDecal = styled.div`
background-color:orange;
width:10%;
height:4px;
margin-bottom:4%;
`
const StyledFaqText = styled.p`
${media.mobileS`
 font-size:0.9rem;
 width:60%;
 margin:5% 20%;
 text-align:center;
`}
${media.tablet`
font-size:1rem;
margin:auto;
`}
font-family:Arial, Helvetica, sans-serif;
color:white;
`

const StyledFaqContainer = styled.div`
${media.mobileS`
display:block;
z-index:-1;
/* height: 80vh; */
`}
${media.laptop`
display:none;
`}
`
const StyledImage = styled(Img)`
${media.mobileS`
width:20%;
`}
${media.tablet`
max-width:100px;
margin-bottom:5%;
`}
`

const StyledContentWrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`

const faqSectionMobile = () => (
  <StaticQuery
      query={ImagesQ}
      render={data => 
    <StyledFaqContainer>

    <StyledTitleContainer>
        <StyledTitle>
            What's Temporal?
        </StyledTitle>  
        <StyledTextDecal/>  
    </StyledTitleContainer>

    <StyledCarouselWrapper>
      <Slider classNames={horizontalCss} infinite="true" >

        <StyledCarousel>
            
             <StyledFaqText> Scalable IPFS Infrastructure to build production-ready applications on top of IPFS quickly with modern tools and languages such as Golang and JavaScript with free starter package featuring turnkey APIs, toolkits &amp; storage. Fully Automated - Install it and go. </StyledFaqText>       
        </StyledCarousel>

            <StyledCarousel>   
            <StyledContentWrapper>
            <StyledImage fluid={data.image1.childImageSharp.fluid} />             
              <StyledFaqText> Open-Source &amp; No Vendor Lock</StyledFaqText>
              </StyledContentWrapper>
            </StyledCarousel>

            <StyledCarousel>
            <StyledContentWrapper>
            <StyledImage fluid={data.image2.childImageSharp.fluid} />
              <StyledFaqText> Plug Into Any Existing Application Seamlessly</StyledFaqText>
              </StyledContentWrapper>
            </StyledCarousel>

            <StyledCarousel>
            <StyledContentWrapper>
            <StyledImage fluid={data.image3.childImageSharp.fluid} />
              <StyledFaqText> Verifiable Data Integrity Guaranteed </StyledFaqText>
            </StyledContentWrapper>
            </StyledCarousel>

            <StyledCarousel>
            <StyledContentWrapper>
              <StyledImage fluid={data.image4.childImageSharp.fluid} /> 
              <StyledFaqText>Enterprise Pinning Service </StyledFaqText>
              </StyledContentWrapper>
            </StyledCarousel>

      </Slider>
    </StyledCarouselWrapper>


    </StyledFaqContainer>  

      }

    />
  )


export const ImagesQ = graphql`
  fragment mobileFAQSectionImage on File {
    childImageSharp {
      fluid(maxWidth: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    image1: file(relativePath: { eq: "mobileFAQ/open.png" }) {
      ...mobileFAQSectionImage
    }

    image2: file(relativePath: { eq: "mobileFAQ/plug.png" }) {
      ...mobileFAQSectionImage
    }
    image3: file(relativePath: { eq: "mobileFAQ/integrity.png" }) {
      ...mobileFAQSectionImage
    }
    image4: file(relativePath: { eq: "mobileFAQ/pin.png" }) {
      ...mobileFAQSectionImage
    }
    

  }
`

export default faqSectionMobile
