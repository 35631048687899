import React from 'react'
import { StaticQuery,graphql } from "gatsby"
import { Link } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
import media from '../media'

import awslogo from '../../images/home/aws.svg'
import miniologo from '../../images/home/minio.svg'

const StyledFeatureTitle = styled.h1`
${media.mobileS`
font-size:1.5rem;
width:100%;
text-align:center;
padding-left:4%;
`}
${media.laptopL`
font-size:1.5rem;
width:90%;
padding-right:10%;
`}
font-size:1.9rem;
white-space: nowrap;
`


const StyledAboutSectionContainer = styled.div`
${media.mobileS`
  flex-direction:column;
`}
${media.tablet`
flex-direction:row;
margin: 4rem 0rem;
`}
display:flex;
flex-direction:row;

`
const StyledAboutTextContainer = styled.div`
${media.mobileS`
width:100%;
`}
${media.tablet`
width:50%;
`}
width:50%;
`
const StyledAboutImageContainer = styled.div`
${media.mobileS`
width:90%;
align-self:center;
padding-bottom:5%;
z-index:-1;
`}
${media.tablet`
width:50%;
padding:5%;
`}
${media.laptop`
width:50%;
padding-left:10%;
padding-right:10%;
padding-top:5%;
`}
`
const StyledAboutTitleDecal = styled.div`
${media.mobileS`
display:none;
`}
${media.laptopL`
display:block;
background-color:black;
width:25%;
height:4px;
margin: 2% 1%;
justify-self:center;
`}
`
const StyledAboutTextDecal = styled.div`
${media.mobileS`
margin:auto;
`}
background-color:orange;
width:15%;
height:4px;
margin-left: 10%;
padding-right: 7%;
margin-right: 7%;
`
const StyledAboutText = styled.p`
font-family:sans-serif;
${
  media.mobileS`
  text-align:center;
  padding: 5%;
  font-size:0.8rem;
  margin:auto;
  `
}
${
  media.laptopL`
  font-size:1rem;
  text-align:center;
  padding: 5%;

  `
}
${
  media.desktop`
  padding:5 5%;
  font-size:1.5rem;
  text-align:start;
  line-height:2.5rem;
  `
}

/* padding-left:28%; */
/* font-size:1rem; */

`
const StyledAboutBottomText = styled.p`
font-family:sans-serif;
${
  media.mobileS`
  text-align:center;
  padding: 5%;
  font-size:0.8rem;
  margin:auto;
  `
}
${
  media.laptopL`
  font-size:1rem;
  text-align:center;
  padding: 5%;
  padding-left:15%;
  `
}
${
  media.desktop`
  padding:5 5%;
  padding-left:18%
  font-size:1.5rem;
  text-align:left;
  line-height:2.5rem;
  `
}


`
const StyledFeatureContainer = styled.div`

${media.mobileS`
  flex-direction:column;
`}
${media.laptopL`
flex-direction:row;
`}
display:flex;
align-items:flex-start;
padding:0 2%;
`
const StyledFeaturedSection = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
width:90%;
margin:auto;
`
const StyledFeaturedInTitle = styled.h2`
font-size:1.5rem;
color:orange;
`
const StyledLogosContainer = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;

margin:auto;
${media.mobileS`
  flex-wrap:wrap;
  width:90%;
`}
${media.tablet`
width:100%;
`}
${media.laptop`
flex-wrap:nowrap;

`}

`
const StyledLogo = styled.div`

${media.mobileS`
width:30%;
padding-right:3%;
padding-bottom:5%;
`}
${media.tablet`
width:10%;
padding-right:0%;
padding-bottom:0%;
`}
`

const StyledLogoPH = styled.div`

${media.mobileS`
width:30%;
padding-right:3%;
padding-bottom:5%;
`}
${media.tablet`
display:none;
`}
`

const StyledHydroLogo = styled.div`
${media.mobileS`
width:25%;
padding-right:3%;
margin-bottom:5%;
`}
${media.tablet`
width:8%;
padding-right:0%;
margin-bottom:0%;
`}
`
const StyledDappLogo = styled.div`
${media.mobileS`
width:30%;
padding-right:3%;
padding-bottom:5%;
`}
${media.tablet`
width:8%;
padding-right:0%;
padding-bottom:0%;
`}
`
const StyledBlkstackLogo = styled.div`
${media.mobileS`
width:30%;
padding-right:3%;
margin-bottom:5%;
`}
${media.tablet`
width:8%;
padding-right:0%;
margin-bottom:0%;
`}
${media.tablet`
max-width:35px;
`}
${media.laptop`
max-width:350px;
`}

`

const StyledS3BannerContainer = styled.div`
display:flex;
flex-direction:row;
align-content:center;

${media.mobileS`

`}
${media.tablet`

`}
${media.tablet`

`}
${media.laptop`
padding:1% 0;
`}

`
const StyledAwsContainer = styled.div`

${media.mobileS`
width:80px;
order:1;
`}
${media.laptop`
width:160px;
order:1;
/* margin-bottom:0;
margin-left:15%; */
`}
${media.desktop`
width:200px;

`}
`

const StyledMinioContainer = styled.div`
/* width:30px; */
${media.mobileS`
width:110px;
order:2;
`}
${media.laptop`
width:200px;
order:3;
`}
${media.desktop`
width:260px;
order:3;
`}
`

const StyledS3LogosContainer = styled.div`
display:flex;
flex-direction:row;
justify-content:space-around;
align-items:center;
margin:0;

${media.mobileS`
  flex-wrap:wrap;
  width:100%;
`}
${media.tablet`

`}
${media.laptop`
flex-wrap:nowrap;
padding-top:5%;
width:70%;
margin:auto;
`}
${media.desktop`
flex-wrap:nowrap;
padding-top:5%;
width:70%;
margin:auto;
`}

`

const StyledS3FeatureTitle = styled.p`
font-family:sans-serif;
text-decoration:underline;
font-size:1.9rem;
font-weight:bold;
white-space: nowrap;
${media.mobileS`
font-size:1.5rem;
width:100%;
text-align:center;
padding-left:4%;
padding-bottom:10%;
order:3;
`}
${media.laptop`
width:100%;
font-weight:600;
padding-left:3%;
padding-bottom:0%;
order:2;
`}
${media.laptopL`
font-size:2rem;
width:100%;
font-weight:800;
padding-left:3%;
padding-bottom:0%;
order:2;
`}

`


const StyledS3FeatureTitleMobile = styled.p`
font-family:sans-serif;
${media.mobileS`
display:flex;
font-size:1.5rem;
width:100%;
text-align:center;
padding-left:4%;
padding-bottom:10%;
`}
${media.tablet`
display:none;
`}
font-size:1.9rem;
font-weight:bold;
white-space: nowrap;
`



const StyledDivider = styled.div`
background-color:orange;
${media.mobileS`
display:none;
`}
${media.tablet`
display:none;
`}
${media.laptop`
display:flex;
height:50px;
width:100%;
`}
`




const aboutSection = () => (
  <StaticQuery
      query={ImagesQ}
      render={data =>

    <div>

      <StyledS3BannerContainer>
     
        <StyledS3LogosContainer>
          <StyledAwsContainer>
          <img src={awslogo}/>
          </StyledAwsContainer>
          <StyledS3FeatureTitle > <Link to="/temporalx" style={{color:"black",textDecoration:"none"}}>S3 Compatible API </Link></StyledS3FeatureTitle>
          <StyledMinioContainer>
          <img src={miniologo}/>
          </StyledMinioContainer>
        </StyledS3LogosContainer>

      </StyledS3BannerContainer>
      <StyledDivider/>
        <StyledAboutSectionContainer >
        <StyledAboutTextContainer>

            <StyledFeatureContainer>
               <StyledAboutTitleDecal />
               <StyledFeatureTitle> The Temporal Advantage </StyledFeatureTitle>
            </StyledFeatureContainer>

            <StyledFeatureContainer>
              <StyledAboutTextDecal/>
              <StyledAboutText>
              Temporal’s streamlined APIs saves money and makes it fast and easy for all sizes and types of enterprise to efficiently store and manage large flows of big data.  
              </StyledAboutText>
            </StyledFeatureContainer>
            
            <StyledAboutBottomText>
            Temporal gives you the tools to instantly scale quickly and safely on the IPFS network for any application. Our IPFS dedicated data center ensures quality and resiliency for all our clients.
            </StyledAboutBottomText>
              
            <StyledAboutBottomText>
            Connect your applications immediately with access to one of our <Link style={{color:"orange"}} to="/dev">3 APIs </Link> and free 3GB storage to <Link style={{color:"orange"}} to="#playground">start pinning </Link> and uploading right away. Or <Link style={{color:"orange"}} to="/contact">contact us</Link>  for a custom solution. 
            </StyledAboutBottomText>

            </StyledAboutTextContainer>


            <StyledAboutImageContainer>
               <Img fluid={data.contactImage.childImageSharp.fluid} />
            </StyledAboutImageContainer>


        </StyledAboutSectionContainer>

        <StyledFeaturedSection>
          <StyledFeaturedInTitle>
            Featured In
          </StyledFeaturedInTitle>
          <StyledLogosContainer>
            <StyledLogo>
              <Img fluid={data.state.childImageSharp.fluid} />
            </StyledLogo>
            <StyledBlkstackLogo>
              <Img fluid={data.blkstack.childImageSharp.fluid} />
            </StyledBlkstackLogo>
            <StyledHydroLogo>
              <Img fluid={data.hydro.childImageSharp.fluid} />
            </StyledHydroLogo>
            <StyledLogo>
              <Img fluid={data.awesome.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo>
              <Img fluid={data.pw.childImageSharp.fluid} />
            </StyledLogo>
              <StyledLogo>
              <Img fluid={data.cryptoslate.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogo>
              <Img fluid={data.cointelegraph.childImageSharp.fluid} />
            </StyledLogo>
            <StyledLogoPH/>
          </StyledLogosContainer>
        </StyledFeaturedSection>
  
        </div>
        
        }
    />


  )




export const ImagesQ = graphql`
  fragment AboutSectionImages on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    contactImage: file(relativePath: { eq: "home/contactImage.jpg"  }) {
      ...AboutSectionImages
    }

    state: file(relativePath: { eq: "featuredin/state.png" }) {
      ...AboutSectionImages
    }
    pw: file(relativePath: { eq: "featuredin/pw.png" }) {
      ...AboutSectionImages
    }
    hydro: file(relativePath: { eq: "featuredin/hydro.png" }) {
      ...AboutSectionImages
    }
    awesome: file(relativePath: { eq: "featuredin/awesomeipfs.png" }) {
      ...AboutSectionImages
    }
    dapp: file(relativePath: { eq: "featuredin/dapp.png" }) {
      ...AboutSectionImages
    }
    blkstack: file(relativePath: { eq: "featuredin/blkstack.png" }) {
      ...AboutSectionImages
    }
    cryptoslate: file(relativePath: { eq: "featuredin/cryptoslate.png" }) {
      ...AboutSectionImages
    }
    cointelegraph: file(relativePath: { eq: "featuredin/cointelegraph.png" }) {
      ...AboutSectionImages
    }
   

  }
`

export default aboutSection




