import React from 'react'
import CookieConsent from "react-cookie-consent";
import styled from 'styled-components'

const StyledCookieMessage = styled.p`
font-family:sans-serif;
`
const StyledPrivacyLInk = styled.a`
text-decoration:none;
font-family:sans-serif;
color:white;
&:hover{
    color:orange;
}
`
const StyledCookieWrapper = styled.div`
display:flex;
justify-content:space-between;
`

export default function cookieConsent() {
    return (
       
<CookieConsent
    location="bottom"
    buttonText="Accept"
    cookieName="TemporalCookies"
    style={{ background: "#212121" }}
    buttonStyle={{ color: "white", fontSize: "16px",fontFamily: "Arial",background:"orange" }}
    expires={150}
    // debug={true}
>
    <StyledCookieWrapper>
    <StyledCookieMessage>This website uses cookies to enhance the user experience.</StyledCookieMessage> 
    <StyledPrivacyLInk href="https://gateway.temporal.cloud/ipns/docs.pp.temporal.cloud">View Our Privacy Policy</StyledPrivacyLInk>
    </StyledCookieWrapper>
</CookieConsent>
       
    )
}
